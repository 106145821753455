import React from "react";
import {
  AllContents,
  ButtonBox,
  ButtonBoxButton,
  ButtonBoxText,
  Container,
  ContainerRegulament,
  ContentTopFooter,
  FooterContainer,
  Imprensa,
  Line,
  Midia,
  Regulamentation,
  Selo,
  TopContent,
} from "./styles";
import { TfiFacebook } from "react-icons/tfi";
import { RiTwitterFill } from "react-icons/ri";
import { FaTiktok } from "react-icons/fa";
import { ImgInstagram } from "../../components/Footer/styles";

interface IFooter {
  active?: boolean;
}

export const Footer: React.FC<IFooter> = ({ active }) => {
  const SeloMelhoresFranquias = require("../../assets/Images/selo-franquias.webp");
  const SeloABF = require("../../assets/Images/abf-selo.png");
  const HospitalPequenoPrincipe = require("../../assets/Images/hospital_pequeno_principe.png");

  return (
    <Container>
      <ButtonBox active={active}>
        <ButtonBoxText>Venha fazer parte da nossa história</ButtonBoxText>

        <ButtonBoxButton>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://franquia.thebestacai.com.br/sejaumfranqueado
 "
          >
            Seja um Franqueado
          </a>
        </ButtonBoxButton>
      </ButtonBox>
      <FooterContainer>
        <TopContent>
          <AllContents>
            <ContentTopFooter>
              <a href="/sobre">Sobre o The Best Açaí</a>
              <a href="/thebest-club">Clube The Best</a>
              <a href="/trabalhe-conosco">Trabalhe conosco</a>
              <a
                className="buttonFranchisee"
                target="_blank"
                rel="noreferrer"
                href="https://franquia.thebestacai.com.br/sejaumfranqueado
 "
              >
                Seja um Franqueado
              </a>
              <a
                className="buttonFranchisee"
                target="_blank"
                rel="noreferrer"
                href="https://gaa.digital/thebest"
              >
                Canal de Denúncias
              </a>
            </ContentTopFooter>
            <Midia>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/thebestacaiofficial/?locale=pt_BR"
              >
                <TfiFacebook color="var(--white)" size={25} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/thebestacaiofficial/"
              >
                <ImgInstagram size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/thebest_acai"
              >
                <RiTwitterFill color="var(--white)" size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tiktok.com/@thebestacaiofficial"
              >
                <FaTiktok color="var(--white)" size={26} />
              </a>
            </Midia>
          </AllContents>

          <Imprensa>
            <span>Assessoria de Imprensa </span>
            <span>Julia Nascimento</span>
            <span>comunicacao@thebestacai.com.br</span>
          </Imprensa>

          <Line />
          <ContainerRegulament>
            <Regulamentation>
              <a className="first-link" href="/regulamento">
                Regulamento do Clube The Best
              </a>
              <a href="/privacidade">Privacidade de dados</a>
              <a
                href="https://upload-autheticator.s3.us-east-2.amazonaws.com/725200dac82f7a14842139c98129043f8c8cde7d"
                target="blank"
              >
                Tabela nutricional
              </a>
            </Regulamentation>
            <a
              href="https://franquias.portaldofranchising.com.br/franquia-the-best-acai/"
              target="blank"
            >
              <Selo src={SeloABF} alt="Selo ABF" />
            </a>
            <a
              href="https://revistapegn.globo.com/melhores-franquias/noticia/2023/06/conheca-as-franquias-5-estrelas-do-premio-as-melhores-franquias-do-brasil-2023.ghtml"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <Selo src={SeloMelhoresFranquias} alt="Melhores Franquias" />
            </a>
            <a
              href="https://pequenoprincipe.org.br/"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <Selo
                src={HospitalPequenoPrincipe}
                alt="Hospital Pequeno Príncipe"
              />
            </a>
          </ContainerRegulament>
        </TopContent>
      </FooterContainer>
    </Container>
  );
};
